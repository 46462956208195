<template>
  <section class="container-fluid">
      <titulo-divisor titulo="Descripción" />
      <div class="row">
        <p v-if="informacion && informacion.descripcion" class="col-12 f-14 lh-18">
          {{ informacion.descripcion }}
        </p>
      </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

      computed:{
        ...mapGetters({
            informacion: 'obras/informacion/informacion'

        })
    },
}
</script>

<style>

</style>